import DpGridMixin from 'src/core/components/DpGrid/DpGridMixin.js'
import DpGridPageMixin from 'src/core/components/DpGrid/DpGridPageMixin.js'
import DpGridAcoes from 'src/core/components/DpGrid/DpGridAcoes.vue'
import DpGrid from 'src/core/components/DpGrid/DpGrid2.vue'
import DpGridStore from 'src/store/dp-grid/'
import vTopFilters from 'src/core/filters/vTopFilters'
import CrederePropostaModel from 'src/model/usuario/CrederePropostaModel'

export default {
  name: 'PropostaGrid',
  components: { DpGrid, DpGridAcoes },
  mixins: [DpGridMixin, DpGridPageMixin, vTopFilters],
  data () {
    return {
      modelPadrao: new CrederePropostaModel()
    }
  },
  mounted () {
    if (!this.$store.hasModule('dp-grid')) this.$store.registerModule('dp-grid', DpGridStore)
  },
  methods: {
    parseRequestLog (form) {
      let dadosSimulacao = null
      let dados = {}

      // Dados Simulação credere
      dadosSimulacao = JSON.parse(form.request_log ? form.request_log.response : null)
      let simulacao = null
      if (dadosSimulacao) simulacao = dadosSimulacao.complete_proposal ?? dadosSimulacao.proposal

      if (simulacao) {
        const { proposal_attempt: tentativa } = simulacao
        if (tentativa) {
          let { bank: banco, financed_amount_in_cents: valor, term_financing: parcelas, quota_in_cents: parcela, created_at: data } = tentativa
          valor = (valor / 100)
          dados = { valor, banco: banco.name, parcelas, valorParcela: (parcela.toFixed(2) / 100), data }
        }
      }
      /* Fim - Dados Simulação credere */

      if (!dados.parcelas && form.banco_id) dados.parcelas = form.parcela
      if (!dados.valorParcela && form.banco_id) dados.valorParcela = form.valor_parcela
      if (!dados.valor && form.banco_id) dados.valor = form.valor_total

      return dados
    }
  }
}
